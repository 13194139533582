import React from "react";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet";
import Layout from "../../layout";
import config from "../../../data/SiteConfig";

import "./Terms.css";

const overview = `
This site is exclusively operated by me, Joseph. Throughout this site, you will see the terms "I" and "me" (referring to myself, Joseph) 
and "you" (referring to yourself, the user).

By using this site you are consenting to the terms outlined below.
`;

const educationOnly = `
The information contained on this website and the resources available for download through this website are for educational and informational purposes only.`; 


const disclaimer = `
The information contained on this website and the resources available for download through this website are not intended as, and shall not be understood or construed as, legal, tax, investment, professional, or financial advice. 
I am not an attorney, accountant, or financial advisor, nor am I holding myself out to be, and the information contained on this website is not a substitute for legal, tax, investment, professional, or financial advice from a professional who is aware of the facts and circumstances of your individual situation.
I have done my best to ensure that the information provided on this website and the resources available for download are accurate and provide valuable information. 
Regardless of anything to the contrary, nothing available on or through this website should be understood as a recommendation that you should not consult with a legal, tax, investment, professional, or financial professional to address your particular information. 
I expressly recommend that you seek advice from a professional.
Neither the I nor any featured authors for this site shall be held liable or responsible for any errors or omissions on this website or for any damage you may suffer as a result of failing to seek competent financial advice from a professional who is familiar with your situation.`;

const infomrationCollection = `
I am the sole owner of the information collected on this site. I only have access to/collect information that you voluntarily give me via email or other direct contacts from you. I will not sell or rent this information to anyone.
I will use your information to respond to you, regarding the reason you contacted me. I will not share your information with any third party outside of my organization, other than as necessary to fulfill your request.
Unless you ask me not to, I may contact you via email in the future to tell you about new posts, new products or services, or changes to this privacy policy.
You may opt-out of any future contacts from me at any time.
You can do the following at any time by contacting me via **joseph@firebudgets.com**:

* See what data I have about you if any.
* Change/correct any data I have about you.
* Have me delete any data I have about you.
* Express any concern you have about our use of your data.
`;


function Terms() {
  return (
  <Layout>
    <div className="terms-container">
      <Helmet title={`Terms and Conditions | ${config.siteTitle}`} />
        <div className="terms">
          <h1> Terms & Conditions</h1>
          <h2>Overview</h2>
          <ReactMarkdown source={overview} className="terms-text"/>
          <h2>Disclaimers</h2>
          <h3>For Educational and Informational Purposes Only</h3>
          <ReactMarkdown source={educationOnly} className="terms-text"/>
          <h3>Not Financial, Legal, Tax, or Professional Advice</h3>
          <ReactMarkdown source={disclaimer} className="terms-text"/>
          <h2>Privacy Policy</h2>
          <h3>Information Collection, Use, and Sharing</h3>
          <ReactMarkdown source={infomrationCollection} className="terms-text"/>
          
        </div>
    </div>
  </Layout>
  );
}

export default Terms;
